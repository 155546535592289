import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-refresh-loader',
  standalone: true,
  imports: [],
  templateUrl: './refresh-loader.component.html',
  styleUrl: './refresh-loader.component.css',
})
export class RefreshLoaderComponent {
  @Input() loadingText = 'Just a moment...';
}
